import Vue from 'vue';
import progressBar from '../progress-bar/index.vue';
import './index.css';
export default {
  name: 'input-customer',
  props: {
    value: {
      type: String,
      default: () => ''
    },
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: ''
    },
    border: {
      type: Boolean,
      default: true,
    },
    inline: {
      type: Boolean,
      default: true,
    }
  },
  components: {progressBar},
  data() {
    return {
      inputValue: '',
      focus: false,
      loading: false,
      suggestions: []
    }
  },
  created() {
    this.$nextTick(() => {
      this.inputValue = this.$props.value;
      this.listeners();
    });
  },
  watch: {
    'inputValue'(newVal) {
      this.$emit('input', newVal);
      this.$emit('change', newVal); 
      if (newVal && newVal.replace(/\D/g, "").length >= 10 && this.focus && !this.loading) this.suggest();
      else {
        this.suggestions = [];
        if (this.focus) this.$emit('suggest', null)
      }
    },
    '$props.value'(newVal) {
      if (this.inputValue != newVal) this.inputValue = newVal;
    },
    'suggestions'(suggestions) {
      if (suggestions && suggestions.length && suggestions[0] && suggestions[0]['passport_number']) this.$nextTick(() => this.onSelect(suggestions[0], false));
    }
  },
  computed: {
    id() {
      return this._uid;
    }
  },
  methods: {
    listeners() {
      document.addEventListener('click', e => {
          if (this.$refs && this.$refs.component && !this.$refs.component.contains(e.target)) this.onBlur();
      });
    },
    onSelect(suggest, close = true) {
      if (suggest) this.inputValue = suggest.passport_number;
      this.$emit('suggest', suggest);
      if (close) this.onBlur();
    },
    suggest() {
      this.loading = true;
      let customers = [];

      const searchNoSpaces = this.inputValue.replace(/[^0-9]/g, '');
      const splited = searchNoSpaces.split('')
      const searchSpaces = `${splited.slice(0, 2).join('')} ${splited.slice(2, 4).join('')} ${splited.slice(4).join('')}`
      const data = {filter: [['passport_number', '%', searchNoSpaces], 'or', ['passport_number', '%', searchSpaces]]};

      return Vue.http.get(this.$root.endpoint + '/customers', {params: data}).then(
        response => {
          let data = []
          if (response && response.body && response.body.response) data = Object.assign({}, response.body.response);
          if (data && data.data && Array.isArray(data.data)) data = Object.assign([], data.data);
          if (Object.values(data).length > 0) {
            customers = Object.values(data).map(item => {
              item.label = item.passport_number + ' - ' + item.name;
              return item;
            });
            this.suggestions = Object.assign([], customers);
          } else this.$emit('suggest', null);
        }, () => this.$emit('suggest', null)
      ).finally(() => this.loading = false);
    },
    onFocus() {
      this.focus = true;
    },
    onBlur() {
      this.focus = false;
    },
    onChange(e) {
      this.inputValue = e.target.value;
    },
    onInput(e) {
      this.inputValue = e.target.value;
    }
  }
}
