import Vue from 'vue';
import progressBar from '../progress-bar/index.vue';
import './index.css';
export default {
  name: 'input-company',
  props: {
    value: {
      type: String,
      default: () => ''
    },
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: ''
    },
    border: {
      type: Boolean,
      default: true,
    },
    inline: {
      type: Boolean,
      default: true,
    }
  },
  components: {progressBar},
  data() {
    return {
      inputValue: '',
      focus: false,
      loading: false,
      suggestions: []
    }
  },
  created() {
    this.$nextTick(() => {
      this.inputValue = this.$props.value;
      this.listeners();
    });
  },
  watch: {
    'inputValue'(newVal) {
      this.$emit('input', newVal);
      this.$emit('change', newVal);

      if (newVal && (newVal.length == 10 || newVal.length == 12) && this.focus && !this.loading) this.suggest();
      else {
        this.suggestions = [];
        if (this.focus) this.$emit('suggest', null);
      }
    },
    '$props.value'(newVal) {
      if (this.inputValue != newVal) this.inputValue = newVal;
    },
    //'suggestions'(suggestions) {
      //Убрал автовыбор первого из подбора т.к. ИНН может быть 10 символов, а из подбора будет прилетать на 12 - другого контрагента
      //if (suggestions && suggestions[0] && suggestions[0]['inn']) this.$nextTick(() => this.onSelect(suggestions[0], false));
    //}
  },
  computed: {
    id() {
      return this._uid;
    }
  },
  methods: {
    listeners() {
      document.addEventListener('click', e => {
          if (this.$refs && this.$refs.component && !this.$refs.component.contains(e.target)) this.onBlur();
      });
    },
    onSelect(suggest, close = true) {
      this.inputValue = suggest.inn;
      this.$emit('suggest', suggest);
      if (close) this.onBlur();
    },
    suggest() {
      this.loading = true;
      let customers = [];

      const searchNoSpaces = this.inputValue.replace(/[^0-9]/g, '');
      const splited = searchNoSpaces.split('')
      const searchSpaces = `${splited.slice(0, 2).join('')} ${splited.slice(2, 4).join('')} ${splited.slice(4).join('')}`
      const data = {filter: [['inn', '%', searchNoSpaces], 'or', ['inn', '%', searchSpaces]]};

      return Vue.http.get(this.$root.endpoint + '/customers', {params: data}).then(
        response => {
          let data = []
          if (response && response.body && response.body.response) data = Object.assign({}, response.body.response);
          if (data && data.data && Array.isArray(data.data)) data = Object.assign([], data.data);

          let existHids = [];
          customers = Object.values(data).map(item => {
            item.label = item.inn + ' - ' + item.name;
            if (item.hid) existHids.push(item.hid);
            return item;
          });
          return this.suggestDaData().then(items => {
            items.map(item => {
              if (!(existHids.indexOf(item.hid)+1)) customers.push(item)
            });
            this.suggestions = Object.assign([], customers);
            return this.suggestions;
          });
        }
      ).finally(() => this.loading = false);
    },
    suggestDaData() {
      this.loading = true;
      return Vue.http.get(this.$root.endpoint + '/suggest?count=20&type=party&query=' + this.inputValue).then(
        response => {
          let tempValues = [];
          if (response.body.response.value) tempValues.push(response.body.response);
          else tempValues = Object.assign([], response.body.response);
          if (tempValues.length <= 0) return [];
          return tempValues.map(item => {
            return {
              label: item.data.inn + ' - ' + item.data.name.short_with_opf,
              name:  item.data.name.short_with_opf,
              kpp:   item.data.kpp,
              inn:   item.data.inn,
              hid:   item.data.hid,
              type:  (item.data.type === "INDIVIDUAL") ? 3 : 1,
              address_legal: (item.data && item.data.address && item.data.address.value) ? item.data.address.value : null //Добавляем юридический адрес
            };
          });   
        }, () => []
      ).finally(() => this.loading = false);
    },
    onFocus() {
      this.focus = true;
    },
    onBlur() {
      this.focus = false;
    },
    onChange(e) {
      this.inputValue = e.target.value;
    },
    onInput(e) {
      this.inputValue = e.target.value;
    }
  }
}
