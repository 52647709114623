import Vue from 'vue'

export default {
    listen: function (endpoint, context) {
        this.endpoint = endpoint;
        this.context  = context;

        this.handleWin();
        this.handleVue();
        this.handleXMLHttp();
    },

    captureException: function (e) {
        if (!this.context || !this.context.Sentry) return;
        this.context.Sentry.captureException(e);
    },

    log: function(data, e) {
        console.log(data);
        if (e) {
            console.log(e);
            this.captureException(e);
        }
        let PostData = new FormData();
        PostData.append('data', JSON.stringify(data));
        
        let xhr = new XMLHttpRequest();
        xhr.open('POST', (this.endpoint + '/error/log'), true);
        xhr.send(PostData);
        xhr.onload = function () {
            let message = (e && e.message ? (e.message + '\n') : '') + ((data.data && data.data.messages) ? data.data.messages : '');
            let trace = (data.data && data.data.trace) ? data.data.trace.join('\n\t') : '';
            alert('Произошла неизвестная ошибка.\nПожалуйста, сообщите об ошибке администратору, затем нажмите сочетание клавиш Ctrl+Shift+R.\n' +  message + '\n' + trace);
        };
    },

    handleWin: function () {
        window.onerror = (message, url, line, col, e) => {
            let trace = e.stack.toString().split('\n').map(item => item.trim());
            this.log({type: 'Window', data: {messages: message, trace: trace, url: window.location.href}}, e);
        }
    },

    handleVue: function () {
        Vue.config.errorHandler = (e, vm, info) => {
            let trace = [], message = '';
            Object.getOwnPropertyNames(e).map(key => {
                if (key == 'stack')   trace = e[key].toString().split('\n').map(item => item.trim());
                if (key == 'message') message = e[key].toString();
            });
            this.log({
                type: 'Vue',
                data: {
                    info: info, messages: message, trace: trace, url: window.location.href
                }
            }, e);
        }
    },

    handleXMLHttp: function() {
        var sendx = XMLHttpRequest.prototype.send, self = this;
        window.XMLHttpRequest.prototype.send = function () {
            self.handleAsync(this);
            return sendx.apply(this, arguments);
        };
    },

    handleAsync: function(obj) {
        const self = this, onloadx = obj.onload; //onerrorx = obj.onerror, onabortx = obj.onabort, ;

        /*
        function onerror(error) {
            self.log(error);
            if (onerrorx) return onerrorx.apply(this, arguments);
        };
      
        function onabort(error) {
            self.log(error);
            if (onabortx) return onabortx.apply(this, arguments);
        };
        */
            
        function onload(request) {
            request = request.currentTarget;
            
            if (request.status && (request.status > 405) && !(request.responseURL.indexOf('/api/error/log') + 1)) {
                let data = {}, r = JSON.parse(request.response), trace = (r && r.errors) ? r.errors.trace : null;
                if (trace) {
                    trace = trace.slice(0, 5);
                    trace = trace.map(item => {
                        return {
                            file: item.file,
                            line: item.line,
                            class: item.class,
                            function: item.function
                        };
                    });
                }
                data = {
                    errors:   r.errors.errors,
                    file:     r.errors.file,
                    line:     r.errors.line,
                    messages: r.errors.messages,
                    trace:    (trace) ? trace : null,
                    url: window.location.href
                }
                self.log({type: 'XMLHttpRequest', data: data});
            }
            if (onloadx) return onloadx.apply(this, arguments);
        }

        //obj.onerror = onerror;
        //obj.onabort = onabort;
        obj.onload  = onload;
    }
}
